<template>
  <v-btn :loading="loading" color="green" icon @click="download">
    <v-icon>mdi-cloud-download</v-icon>
  </v-btn>
</template>
<script>
import axios from "axios";

export default {
  name: 'DownloadAnswerSheet',
  props: ['examId','userName','userId'],
  data() {
    return {
      dialog: false,
      file: null,
      loading: false,
    }
  },
  computed: {},
  methods: {
    download() {
      const url = 'answer?type=pdf&id=' + this.examId+'&user='+this.userId
      axios.get(url, {
        headers:
            {
              'Content-Disposition': "attachment; filename=template.pdf",
              'Content-Type': 'application/pdf'
            },
        responseType: 'arraybuffer',
      }).then((response) => {
        console.log(response)
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const name = this.examId+'-'+this.userName+'.pdf'
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        console.log(error)
      })
    },
  },
}
</script>