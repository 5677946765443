<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container>
    <v-card>
      <v-card-title class="align-baseline">
        Answer Sheet
      </v-card-title>
      <v-data-table
          dense
          :headers="headers"
          :items="writtenExamAnswers"
          :options.sync="options"
          :server-items-length="totalWrittenExamAnswers"
          :footer-props="footerProps"
          :items-per-page="20"
          :loading="loading"
          item-key="id"
          :search="search"
          class="elevation-1"
      >
        <template v-slot:item.title="{ item }">
          <router-link :to="{name: 'WrittenExamAnswerAnswerSheet',params:{writtenExamAnswerId:item.id}}">
            {{ item.title }}
          </router-link>
        </template>
        <template v-slot:item.started_at="{ item }">
          {{ item.started_at | calendar }}
        </template>
        <template v-slot:item.finished_at="{ item }">
          {{ item.finished_at | calendar }}
        </template>
        <template v-slot:item.actions="{ item }">
          <div>
            <download-answer-sheet :exam-id="item.written_exam_id" :user-name="item.user_name" :user-id="item.user_id"/>
            <v-btn icon @click="editItem(item)">
              <v-icon color="primary" small>mdi-pencil</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
        v-model="dialog"
        max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Review Answer
        </v-card-title>

        <v-card-text>
          <v-form @submit.prevent="save">
            <v-text-field label="Obtained Marks" v-model="editedItem.obtained_mark"/>
            <v-file-input label="Attachment" v-model="editedItem.attachment"/>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="red"
              text
              @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
              color="success"
              text
              @click="save"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from 'axios'
import moment from "moment";
import DownloadAnswerSheet from "@/components/admin/exam/DownloadAnswerSheet";

export default {
  components: {DownloadAnswerSheet},
  data: () => ({
    writtenExamAnswers: [],
    selected: [],
    footerProps: {
      itemsPerPageOptions: [20, 50, 100, 500]
    },
    date: '',
    courses: [],
    course: '',
    menu: false,
    search: '',
    page: 1,
    pageCount: 0,
    totalWrittenExamAnswers: 0,
    options: {},
    loading: false,
    dialog: false,
    dialogDelete: false,
    headers: [
      {text: 'Name', value: 'user_name'},
      {text: 'Phone', value: 'phone'},
      {text: 'Obtained Mark', value: 'obtained_mark'},
      {text: 'Start Time', value: 'started_at'},
      {text: 'End Time', value: 'finished_at'},
      {text: 'Actions', value: 'actions'},
    ],
    editedIndex: -1,
    editedItem: {
      obtained_mark: '',
      attachment: null,
    },
    defaultItem: {
      obtained_mark: '',
      attachment: null,
    },
    selectedItemForDelete: '',
    free: false,
    confirmed: false,
    pending: false,
    filter: '',
    dailyTotal: '',
    monthlyTotal: '',
    yearlyTotal: '',
  }),
  computed: {
    form() {
      let formData = new FormData
      if (this.editedIndex > -1) {
        formData.append('_method', 'PUT')
      }
      formData.append('attachment', this.editedItem.attachment)
      formData.append('obtained_mark', this.editedItem.obtained_mark)
      formData.append('status', 'Reviewed')
      return formData
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    sortTerm() {
      return this.options.sortBy[0] ? '&sort=' + this.options.sortBy[0] : ''
    },
    dateTerm() {
      return this.date ? '&date=' + this.date : ''
    },
    courseTerm() {
      return this.course ? '&course_id=' + this.course : ''
    },
    descTerm() {
      return this.options.sortDesc[0] ? '&desc=' + this.options.sortDesc[0] : ''
    }
  },

  watch: {
    options: {
      handler() {
        this.initialize()
      },
      deep: true,
    },
    dialog(val) {
      val || this.close()
    },
    search() {
      if (this.search.length) {
        this.searchWrittenExamAnswer()
      } else {
        this.initialize()
      }
    },
    date() {
      this.initialize()
    },
    course() {
      this.initialize()
    }
  },
  created() {
    this.loadCourses()
  },
  methods: {
    loadCourses() {
      const url = 'admin/courses?filter=admin'
      axios.get(url).then((response) => {
        this.courses = response.data
      })
    },
    initialize() {
      this.loading = true
      let link = 'admin/written-exam-report?id=' + this.$route.params.writtenExamId
      axios.get(link).then((response) => {
        this.writtenExamAnswers = response.data.data
        this.totalWrittenExamAnswers = response.data.total
        this.loading = false
      })
    },
    searchWrittenExamAnswer(page = 1) {
      this.loading = true
      let link = 'admin/written-exam-report?filter=search&page=' + page + '&query=' + this.search
      axios.get(link).then((response) => {
        this.writteExamAnswers = response.data.data
        this.totalWrittenExamAnswers = response.data.total
        this.loading = false
      })
    },
    editItem(item) {
      this.editedIndex = this.writtenExamAnswers.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem(item) {
      this.editedIndex = this.writteExamAnswers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.selectedItemForDelete = item;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      const url = 'admin/written-exam-report/' + this.selectedItemForDelete.id;
      axios.delete(url).then(() => {
        this.writteExamAnswers.splice(this.editedIndex, 1);
        this.closeDelete();
      }).catch((error) => {
        console.log(error)
      })
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save() {
      if (this.editedIndex > -1) {
        const link = 'admin/written-exam-report/' + this.editedItem.id
        axios.post(link, this.form).then(() => {
          this.initialize()
        })
      }
      this.close()
    },
  },
  filters: {
    calendar(value) {
      return value ? moment(value).format('D-MMM-Y hh:mm a') : 'Not available'
    }
  }
}
</script>